import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import loadable from "@loadable/component";
import { createBrowserHistory } from "history";
// import PdfReport from "./components/Pdf/PdfReport";
const Home = loadable(() => import("./pages/Home/Home"));
const Intro = loadable(() => import("./pages/Intro/Intro"));
const CompletedPage = loadable(() => import("./pages/Report/CompletedPage"));
const SurveyPage = loadable(() => import("./pages/Survey/Survey"));
const Advice = loadable(() => import("./pages/Advice/Advice"));

const history = createBrowserHistory();

const App = () => {
  const [completed, setCompleted] = useState(false);
  const [surveyResult, setSurveyResult] = useState("");

  return (
    <Routes history={history}>
      <Route path="/" element={<Home />} />
      <Route path="/intro" element={<Intro />} />
      <Route
        path="/survey"
        element={
          <SurveyPage
            completed={completed}
            setSurveyResult={setSurveyResult}
            setCompleted={setCompleted}
          />
        }
      />
      <Route
        path="/report" 
        element={<CompletedPage surveyResult={surveyResult} />}
      />
      <Route path="/mitarbeiterberater" element={<Advice surveyResult={surveyResult} />} />
    </Routes>
  );
};

export default App;
